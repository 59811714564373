.Carousel{
	width: 100%;
}
.container-slider{
	display: flex;
}

.slider{
	overflow: hidden;
	width: 100%;
	position: relative;
	height: 530px;
	background-color: black;
}
.titulo1{
	background-color:rgba(255,255,255,0.8);
	width: 45%;
	min-width: fit-content;
	
	/*width: 180px;*/
	position: absolute;
	z-index: 30;
	top: 50%;
	left: 0;
	animation: steam2 2s;
	font-family: 'Open Sans', sans-serif;
    font-weight: 520;
	font-size: 1.2rem;
	line-height: 1.4;
	text-align: left;
	color: #787878;
	padding: 1% 5% 1% 5%;
	/*border-radius: 5px;*/
	margin: 0;
}
.titulo1 p{
	margin: auto;
}
.textoCarousel1{
	font-family: 'Open Sans', sans-serif;
    font-weight: 400;
	font-size: 0.75rem;
}
@keyframes steam2 {
  0% {left: -100%;
  }
  100%{left: 0;
  }
}

.img-slider{
	opacity: 0;
	z-index: 10;
	width: 100%;
	
	position: absolute;
	left: 0;
	top: 0;
	animation: cycle 9s infinite;
	animation-timing-function: ease;
	object-fit: cover;
}

.img-slider2{
	opacity: 0;
	z-index: 10;
	width: 100%;
	top: 0;
	position: absolute;
	
	left: 0;
	
	animation: cycle 9s infinite;
	animation-delay: 3s;
	animation-timing-function: ease;
	object-fit: cover;
}
.img-slider3{
	opacity: 0;
	z-index: 10;
	width: 100%;
	top: 0;
	position: absolute;
	left: 0;
	
	animation: cycle 9s infinite;
	animation-delay: 6s;
	animation-timing-function: ease;
	object-fit: cover;
}


@keyframes cycle {
	0%{
		z-index: 10;
		transform: scale(1);
		opacity: 0.8;
	}
	20%{
		opacity: 1;
		z-index: 20;
	}
	33%{
		opacity: 0.9;
		z-index: 20;
	}
	49.9%{
		opacity:0;
		z-index: 10;
	}
	
	100%{
		opacity: 0;
		transform: scale(1.12);
	}
}


@media screen and (min-width:768px) {
	.slider{
		height: 400px;
	}
	.titulo1{
		font-weight: 520;
		font-size: 1.5rem;
		line-height: 1.5;
	}
	.textoCarousel1{
		font-weight: 520;
		font-size: 1.1rem;
		line-height: 1.5;
	}
}
@media screen and (min-width:1024px) {
	.slider{
		height: 700px;
		
	}
	.titulo1{
		width: 25%;
		min-width: fit-content;
		font-weight: 520;
		font-size: 2rem;
		line-height: 2;
	}
	.textoCarousel1{
		font-weight: 400;
		font-size: 1.5rem;
		line-height: 1.5;
	}
}
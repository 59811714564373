.contPrinI{
    position: relative;
    /*padding-top: 1%;*/
    width: 100%;
    min-height: 600px; 

}
.contPrinI_carta{
    position: absolute;
    display: flex;
    justify-content: center;
    left: 0;
    /*transform: translate(95%,0);*/

    max-width: 90%;
    min-width: 30%;
    z-index: 2;
    
    background-color: rgba(255, 255, 255, 0.89);
    border-top: 5px solid rgba(0, 176, 79,0.85);
    box-shadow: rgba(50, 50, 93, 0.35) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 5px;
}

.contPrinI_carta_text{
    right: 0;
    max-width: 90%;
    z-index: 2;
    top: 30px;
    margin: 0;
}
.contPrinI_carta_text_topTittle1{
    text-align:start;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
    padding-top: 5%;
    padding-bottom: 0;
    font-weight: 600;
    color: rgb(119, 119, 119);

}
.contPrinI_carta_text_P2{
    font-size: 13px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    line-height: 1;
    font-weight: 500;
    line-height: 100%;
    text-align: justify;
    color: rgb(90, 90, 90);
}

.contPrinI_cartaImg2{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.contPrinI_cartaImg2_imagen2{
    min-width: 100%;
   
}
.imgPortada{
    width: 100%;
    min-height: 600px;
    object-fit: cover;
}



@media (min-width: 1024px) {
    .contPrinI{
        position: relative;
        /*padding-top: 1%;*/
        width: 100%;
        height: 500px; 

    }
    .backGround{
        display:block;
    }
    .contPrinI_carta{
        position: absolute;
        display: flex;
        justify-content: center;
        left: 50%;
        transform: translate(-95%,0);
    
        max-width: 45%;
        z-index: 2;
        top: 30px;
        background-color: white;
        border-top: 5px solid rgba(0, 176, 79,0.85);
        box-shadow: rgba(50, 50, 93, 0.35) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        border-radius: 5px;
        
    }

    .contPrinD_carta_text{
        left: 0;
        max-width: 90%;
        z-index: 2;
        top: 30px;
        margin: 0;
    }
    .contPrinD_carta_text_topTittle1{
        text-align: justify;
        font-size: 22px;
        font-family: 'Open Sans', sans-serif;
        padding-top: 2%;
        padding-bottom: 1%;
        font-weight: 600;
        color: rgb(119, 119, 119);
    
    }
    .contPrinI_carta_text_P2{
        font-size: 15px;
        font-family: 'Open Sans', sans-serif;
        line-height: 100%;
        text-align: justify;
        font-weight: 600;
        color: rgb(90, 90, 90);
        width: 100%;
    }

    .contPrinI_cartaImg2{
        background-color: none;
        position: absolute;
        right: 0;
        max-width: 55%;
        z-index: 1;
        top: 100px;
    }
    .contPrinI_cartaImg2_imagen2{
        
        width: 100%;
        height: 400px;
    }
    .imgPortada{
        width: 100%;
        object-fit: cover;
        min-height: 0;
    }

}
.ContTotal{
  display: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: rgb(225, 225, 225);
}

.ContTotal_izq{
  width: 50%;
}
.ContTotal_izq_text{
  width: 80%;
  padding-left: 10%;
}
.ContTotal_izq_text_title{
  font-size: 26px;
  font-weight: 500;
  color: #787878;
  
}
.ContTotal_der{
  width: 50%;
  height:400px;
}
.gmap_canvas{
  height:400px;
}
.pdatos{
  align-items: center;
  margin: auto;
}
.pdatos img{
  width: 35px;
  padding-right: 1%;
}
@media (min-width: 1024px) {
  .ContTotal{
    display: flex;
  }

}
.containerFoot{
    background-color: black;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-size: 0.6rem;
    margin: 0;
    text-align:justify;
    height: 15px;
    display: flex;
    justify-content: space-between;
    padding: 0.25%;
   
    max-width: 100%;
}
.containerFoot p{
    margin: 0;
}
.finishFoot1{
    padding-left: 1.5%;
    width: 45%;
    background-color: black;
    color: white;
    font-family: 'Open Sans', sans-serif;
 
}
.finishFoot2{
    display: none;
    background-color: black;
    color: white;
    font-family: 'Open Sans', sans-serif;
    text-align: center;
 
}
.finishFoot2 a{
    font-size: 0.6rem;
}
.finishFoot3{
    width: 45%;
    background-color: black;
    color: white;
    font-family: 'Open Sans', sans-serif;
    text-align:right;
    align-items: end;
    padding-right:  1.5%;
    padding-bottom: 1% ;
}
.pieDeDatos img{
   
    height: auto;  
}
.pieDeDatos{
    background-color: rgb(50,50,50);
    display: block;
    color: white;
    padding: 0.5%;
    font-family: 'Open Sans', sans-serif;
    font-size: 1rem;
    margin: 0;
    text-align: left;
}

.datos1{
    width: 95%;
    padding: 2% 0% 2% 5%;
}
.datos1 p{
    padding: 0% 0% 0% 1%;
}
.datos1 img{
    width: 290px;
    vertical-align: middle;
    object-fit:contain;
}
.datos2{
    width: 95%;
    vertical-align: middle;
    padding-left: 5%;
}
.datos2 p{
    width: auto;
    margin: 0;
    line-height: 1;
    padding: 0% 0% 0% 1%;
}
.datos2 img{
    width: 30px;
    object-fit:contain;
}
.datos3{
    width: 95%;
    padding-left: 5%;
}
.datos3 p{
    margin: 0;
    padding: 0% 0% 0% 1%;
}
.datos3 img{
    width: 30px;
    object-fit:contain;
   
}
.pdatos{
    display: flex;
    
}


@media screen and (min-width:768px) {
    .containerFoot{
        font-size: 0.8rem;
    }
    .pieDeDatos{
        display: flex;
    }
    .finishFoot1{
        width: 33%;
    }
    .finishFoot2{
        width: 33%;
        display:contents;
    }
    .finishFoot3{
        width: 33%;
    }
    .datos1{
        padding: 0% 0% 0% 0.5%;
    }
    .datos1 img{
        width: 100%;
    }
    .datos2{
        padding: 0% 0% 0% 2%;
        line-height: 1;
    }
    .datos2 img{
        width: 25px;
    }
    .datos3{
        padding: 1.3% 0% 0% 0.5%;
        line-height: 1;
        padding-bottom: 0% ;
    } 
    .datos2 p{
        margin: 0% 0% 0% 1%;
        line-height: 1.5;
     } 
    .datos3 p{
       margin: 0% 0% 0% 1%;
    } 
    .datos3 img{
        line-height: 1;
        height: 25px;
    }

}
@media screen and (min-width:1024px) {
    .finishFoot1{
        width: 33%;
    }
    .finishFoot2{
        width: 33%;
        display:contents;
    }
    .finishFoot3{
        width: 33%;
    }
    .datos3 p{
        padding: 0% 0% 0% 1%;
    }
    .datos3{
        padding: 1% 0% 0% 1%;
    } 
    .datos2{
        padding: 1% 0% 0% 3%;
        justify-content: center;
        align-items: center;
    } 
    .datos2 p{
        margin: 0.5% 0% 0% 5%;
        line-height: 2;
    } 
}

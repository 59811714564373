body{
	all:unset;
	padding: 0;
	margin: 0;
	width: 100%;
	/*box-sizing: border-box;*/
	
}
.separadorStickyTop{
	width: 100%;
	height: 45px;
	background-color: black;
	position: absolute;
	z-index: 100;
}
.mainNav{
	position: sticky;
	top:0px;
	height: 45px;
	z-index: 500;
}
.ContenedorNavTop{
	display: flex;
	height: 45px;
	background-color: rgba(0,0,0,0.7);
	position: relative;
	width: 100%;
	align-items:center;
	justify-content: space-between;
	z-index: 100;
	
}	
.Container_Toggle{
	height: 37px;
	
	display: flex;
	margin: 0;
	object-fit: contain;
	
	justify-content: flex-end;
	cursor: pointer;
}
.Container_Icon{
	height: 37px;
	
	display: flex;
	margin: 0;
	object-fit: contain;
	padding-left: 1%;
}
img{
	/*object-fit: fill;*/
	height: 100%;
}

.mainMenu{
	transform: translateY(0);
	position: relative;
	transition: 0.3s;
	display: flexbox;
	width: 100%;
}
.mainMenu_Hidden{
	
	transform: translateY(-300%);
	
	display: flexbox;
}
.mainMenu ul{
	width:100%;
	height: 45px;
	/*display: flexbox;*/
	margin: 0;
	/*width: 100vw;*/
	height: 100vh;
	background-color: rgba(37, 37, 37, 0.95);
	padding: 3% 0 0 0 ;
	
	
}
.mainMenu__item{
	padding-top: 10%;
}
.mainMenu li,a{
	
	font-size: 18px;
	font-family: 'Open Sans', sans-serif;
	list-style-type: none;
	color: #fff;
	transition: 0.4s;
	text-decoration: none;

}
.desktopMenu{
	display: none;
}

.mainMenu a:hover{
	color: #7dbcff;
	font-weight: 600;
}

@media screen and (min-width:768px) {
	.separadorStickyTop{
		width: 100%;
		height: 45px;
		background-color: black;
		position: absolute;
	}
	.mainMenu{
		display: none;
	}
	.Container_Icon{
		width: 15%;
	}
	.Container_Toggle{
		display: none;
	}
	.ContenedorNavTop{
		justify-content: space-between;
	}
	.desktopMenu{
		display: flex;
		position: relative;
		height: 45px;
		width: 65%;
		justify-content: flex-end;
		padding-right: 2%;
	}
	.desktopMenu ul{
		display: flex;
		margin: 0;
		align-items: center;
		flex: 1;
		padding: 0 0 0 0;
	}
	.desktopMenu li{
		white-space: nowrap;
		text-decoration: none;
		list-style: none;
		font-size: 13px;
		font-weight: 600;
		font-family: 'Open Sans', sans-serif;
		color: #E0E0EF;
		/*padding: auto;*/
		margin: auto;
	}
	.desktopMenu a{
		display: flex;
		transition: 0.1s;
	}

	.desktopMenu__item{
		flex-wrap: wrap;
		display: flex;
	}
	.desktopMenu a:hover {
		color: #7dbcff;
		
		/*font-size: 16px;*/
	}
}
@media screen and (min-width:1024px) {
	
	.desktopMenu{
		display: flex;
		position: relative;
		height: 45px;
		width: 52%;
		justify-content: flex-end;
		padding-right: 1%;
	}

}
.contenedorPrincipal{
    background-color: rgb(247, 247, 247);
    position: relative;

    width: 100%;
    height: 350px;
}
.cartaTexto1{
    position: absolute;
    left: 0;
    background-color: rgba(210, 210, 210,0.87);
    max-width: 90%;
    padding-right: 1%;
    padding-left: 5%;
    padding-bottom: 5%;
    z-index: 3;

}
.texto1{
    display: none;

}
.topTittle{
    font-size: 22px;

    color: rgb(90, 90, 90);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
}
.textoMovil{
    max-width: 95%;
    
}
.textP{
    font-size: 16px;
    color: #343434ec;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    line-height: 1;
    font-weight: 450;
    text-align: justify;
    text-indent: 20px;
}
.cartaImagen1{
    position: absolute;
    right: 0;
    width: 100%;
    height: 500px;
    z-index: 1;

}
.imagen1{

    width: 100%;
    height: 100%;


}
.imgPortada{
    width: 100%;
    object-fit: cover;
}
@media (min-width: 425px) {
    .contenedorPrincipal{
        height: 440px;
    }
    .cartaTexto1{
        max-width: 75%;
        top:35px;
    }
}
@media (min-width: 1024px) {
    .textoMovil{
        display: none;
    }
    .contenedorPrincipal{
        background-color: rgb(247, 247, 247);
        position: relative;
        padding-top: 1%;
        width: 100%;
        height: 500px;
    }
    .cartaTexto1{
        position: absolute;
        left: 0;
        background-color: rgb(210, 210, 210);
        max-width: 50%;
        padding-right: 2%;
        padding-left: 2%;
        padding-bottom: 1%;
        z-index: 3;
        top: 70px;

    }
    .texto1{
        display: contents;
        max-width: 90%;
        z-index: 3;
    }
    .textP{
        font-size: 17px;
        color: #343434;
        font-family: 'Open Sans', sans-serif;
        text-align: justify;
        text-indent: 20px;
    }
    .cartaImagen1{
        position: absolute;
        right: 0;
        width: 48%;
        z-index: 2;
        top: 30px;
    }
    .imagen1{
    
        width: 100%;
        height: 400px;
    
    
    }
    .imgPortada{
        width: 100%;
        object-fit: cover;
    }
}